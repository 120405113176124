import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isLogged: false,
    token: false,
    colorPrincipal: '#2f4f4f',
    saldoPrePago: {
      ativo: false,
      credito: 0,
    },
    logo: null,
    user: {},
  },
  mutations: {
    setConfig(state, config) {
      state.colorPrincipal = config.colorPrincipal;
      state.logo = config.logo;
    },
    login(state, user) {
      state.isLogged = true;
      state.token = localStorage.getItem('token');
      state.colorPrincipal = user.colorPrincipal;
      state.logo = user.logo;
      state.user = { ...user };
      if(user.saldoPrePago) state.saldoPrePago = user.saldoPrePago;
    },
    updateSaldoPrePago(state, saldoPrePago) {
      state.saldoPrePago = saldoPrePago;
    },
    logout(state) {
      state.isLogged = false;
      state.token = false;
      state.user = {};
      state.logo = null;
      state.colorPrincipal = '#2f4f4f';
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
  }
});

export default store