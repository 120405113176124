import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
const Login = () => import('../views/Login.vue');
const Active = () => import('../views/Active.vue');
const Forgot = () => import('../views/Forgot.vue');
const Campanhas = () => import('../views/campanhas/index.vue');
const CampanhasImport = () => import('../views/campanhas/import.vue');
const Detalhes = () => import('../views/campanhas/detalhes.vue');
const Temzap = () => import('../views/temzap/index.vue');
const TemzapImport = () => import('../views/temzap/import.vue');
const TemzapDetalhes = () => import('../views/temzap/detalhes.vue');
const Respostas = () => import('../views/respostas/index.vue');
const Chat = () => import('../views/chat/index.vue');
const RelatorioEnvio = () => import("../views/relatorios/relatorioEnvio.vue");
const RelatorioEnviosClientes = () => import("../views/relatorios/relatorioEnviosClientes.vue");
const RelatorioTemzap = () => import("../views/relatorios/relatorioTemzap.vue");
const RelatorioChats = () => import("../views/relatorios/relatorioChats.vue");
const RelatorioSessao = () => import("../views/relatorios/relatorioSessao.vue");
const RelatorioDiario = () => import("../views/relatorios/relatorioDiario.vue");
const RelatorioResumo = () => import("../views/relatorios/relatorioResumo.vue");
const RelatorioChatsFinalizados = () => import("../views/relatorios/relatorioChatsFinalizados.vue");
const RelatorioAnalitico = () => import("../views/relatorios/RelatorioAnalitico.vue");
const Faturamento = () => import('../views/faturamento/index.vue');
const Operadores = () => import('../views/operadores/index.vue');
const OperadoresForm = () => import('../views/operadores/form.vue');
const MsgProibida = () => import('../views/operadores/msgProibida.vue');
const NumerosProibido = () => import('../views/operadores/numerosBlackList.vue');
const Avaliacoes = () => import('../views/operadores/avaliacoes.vue');
const Centrocusto = () => import('../views/centrocusto/index.vue');
const CentrocustoForm = () => import('../views/centrocusto/form.vue');
const Tag = () => import('../views/tag/index.vue');
const TagForm = () => import('../views/tag/form.vue');
const Auditoria = () => import('../views/auditoria/index.vue');
const Avaliacao = () => import('../views/avaliacao/index.vue');
const Config = () => import('../views/config/index.vue');
const Saldo = () => import('../views/config/saldo.vue');
const User = () => import('../views/User.vue');
const Rastrear = () => import('../views/rastrear/index.vue');
const Templates = () => import('../views/templates/index.vue');
const TemplatesForm = () => import('../views/templates/form.vue');
const Numeros = () => import('../views/numerosWhatsAppOficial/index.vue');
const ChatBot = () => import('../views/chatbot/index.vue');
const NovaConta = () => import('../views/nova-conta/index.vue');
const Home = () => import('../views/home/index.vue');


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/active',
    name: 'Active',
    component: Active,
  },
  {
    path: '/reset-senha',
    name: 'SendEmailResetPassword',
    component: Forgot,
  },
  {
    path: '/reset-senha/:token',
    name: 'PasswordChange',
    component: Forgot,
  },
  {
    path: '/campanhas',
    name: 'Campanhas',
    component: Campanhas,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'campanhas' }
  },
  {
    path: '/campanhas/import',
    name: 'CampanhasImport',
    component: CampanhasImport,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'campanhas' }
  },
  {
    path: '/campanhas/detalhes/:id',
    name: 'CampanhasDetalhes',
    component: Detalhes,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'campanhas' }
  },

  {
    path: '/numeros',
    name: 'Numeros',
    component: Numeros,
    meta: { requiresLogin: true, requiresAcesso: false, acesso: 'whatsappApiOficial' }
  },

  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    meta: { requiresLogin: true, requiresAcesso: false, acesso: 'whatsappApiOficial' }
  },
  {
    path: '/templates/form/:id',
    name: 'TemplatesFormEdit',
    component: TemplatesForm,
    meta: { requiresLogin: true, requiresAcesso: false, acesso: 'whatsappApiOficial' }
  },
  {
    path: '/templates/form',
    name: 'TemplatesForm',
    component: TemplatesForm,
    meta: { requiresLogin: true, requiresAcesso: false, acesso: 'whatsappApiOficial' }
  },

  {
    path: '/chatbot',
    name: 'ChatBot',
    component: ChatBot,
    meta: { requiresLogin: true, requiresAcesso: false, acesso: 'whatsappApiOficial' }
  },

  {
    path: '/temzap',
    name: 'Validacao',
    component: Temzap,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'validacao' }
  },
  {
    path: '/temzap/import',
    name: 'ValidacaoImport',
    component: TemzapImport,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'validacao' }
  },
  {
    path: '/temzap/detalhes/:id',
    name: 'ValidacaoDetalhes',
    component: TemzapDetalhes,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'validacao' }
  },
  {
    path: '/respostas',
    name: "Respostas",
    component: Respostas,
    meta: { requiresLogin: true, requiresAdmin: true }
  },

  {
    path: '/chats',
    name: "Chats",
    component: Chat,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'chats' }
  },
  {
    path: '/chats/messages/:id',
    name: "ChatsMessagesID",
    component: Chat,
    meta: { requiresLogin: true }
  },

  {
    path: '/config',
    name: "Config",
    component: Config,
    meta: { requiresLogin: true, requiresAdmin: true }
  },

  {
    path: '/saldo',
    name: "Saldo",
    component: Saldo,
    meta: { requiresLogin: true, requiresAdmin: true }
  },

  {
    path: '/user',
    name: "User",
    component: User,
    meta: { requiresLogin: true }
  },

  {
    path: '/avaliacoes',
    name: 'Avaliacoes',
    component: Avaliacoes,
    meta: { requiresLogin: true }
  },

  {
    path: '/operadores',
    name: "Operadores",
    component: Operadores,
    meta: { requiresLogin: true }
  },
  {
    path: '/operadores/form',
    name: 'OperadoresForm',
    component: OperadoresForm,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/operadores/form/:id',
    name: 'OperadoresFormEdit',
    component: OperadoresForm,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/operadores/avaliacoes/:id',
    name: 'OperadoresAvaliacoes',
    component: Avaliacoes,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/operadores/msgproibida',
    name: 'OperadoresMsgProibida',
    component: MsgProibida,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/operadores/numerosproibido',
    name: 'OperadoresNumerosProibido',
    component: NumerosProibido,
    meta: { requiresLogin: true, requiresAdmin: true }
  },

  {
    path: '/numerosproibido',
    name: 'NumerosProibido',
    component: NumerosProibido,
    meta: { requiresLogin: true, requiresAdmin: true }
  },

  {
    path: '/avaliacao/:id',
    name: "Avaliacao",
    component: Avaliacao,
    meta: { requiresLogin: false }
  },

  {
    path: '/centros',
    name: "Centros",
    component: Centrocusto,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/centros/form',
    name: 'CentrosForm',
    component: CentrocustoForm,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/centros/form/:id',
    name: 'CentrosFormEdit',
    component: CentrocustoForm,
    meta: { requiresLogin: true, requiresAdmin: true }
  },

  {
    path: '/rastrear',
    name: "Rastrear",
    component: Rastrear,
    meta: { requiresLogin: true, acesso: 'rastrear' }
  },

  {
    path: '/tag',
    name: "Tag",
    component: Tag,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/tag/form',
    name: 'TagForm',
    component: TagForm,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/tag/form/:id',
    name: 'TagFormEdit',
    component: TagForm,
    meta: { requiresLogin: true, requiresAdmin: true }
  },

  {
    path: '/relatorios/auditoria',
    name: "Relatórios/Auditoria",
    component: Auditoria,
    meta: { requiresLogin: true }
  },
  {
    path: '/relatorios/faturamento',
    name: "Relatórios/Faturamento",
    component: Faturamento,
    meta: { requiresLogin: true, requiresAdmin: true }
  },
  {
    path: '/relatorios/relatorio-envio',
    name: "Relatórios/Relatório de Envio",
    component: RelatorioEnvio,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_campanha' }
  },
  {
    path: '/relatorios/relatorio-envio-clientes',
    name: "Relatórios/Relatório de Envio Clientes",
    component: RelatorioEnviosClientes,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_campanha' }
  },
  {
    path: '/relatorios/relatorio-temzap',
    name: "Relatórios/Relatório tem zap",
    component: RelatorioTemzap,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_validacao' }
  },
  {
    path: '/relatorios/relatorio-analitico',
    name: "Relatórios/Relatório analítico",
    component: RelatorioAnalitico,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_campanha' }
  },
  {
    path: '/relatorios/relatorio-chats',
    name: "Relatórios/Relatório de Chats",
    component: RelatorioChats,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_campanha' }
  },
  {
    path: '/relatorios/relatorio-chats-finalizados',
    name: "Relatórios/Relatório de Chats Finalizados",
    component: RelatorioChatsFinalizados,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_campanha' }
  },
  {
    path: '/relatorios/relatorio-diario',
    name: "Relatórios/Relatório diario",
    component: RelatorioDiario,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_campanha' }
  },
  {
    path: '/relatorios/relatorio-resumo',
    name: "Relatórios/Relatório resumo",
    component: RelatorioResumo,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_campanha' }
  },
  {
    path: '/relatorios/relatorio-sessao',
    name: "Relatórios/Relatório de Sessao",
    component: RelatorioSessao,
    meta: { requiresLogin: true, requiresAcesso: true, acesso: 'relatorio_campanha' }
  },
  {
    path: '/nova-conta-novavida',
    name: NovaConta,
    component: NovaConta,
    meta: { }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  const hasToken = localStorage.getItem('token');

  if (hasToken && !store.state.isLogged) {
    store.commit('login', JSON.parse(localStorage.getItem('user')));
    next();
  } else if (to.meta.requiresLogin && !store.state.isLogged) {
    next({ name: 'Login' })
  } else if (to.name === 'Login' && hasToken && store.state.isLogged) {
    next(from);
  } else if (to.meta.requiresAcesso && to.meta.acesso) {
    if (store.state.user && store.state.user.acessos_lzv2 && store.state.user.acessos_lzv2.indexOf(to.meta.acesso) !== -1) {
      next();
    } else {
      next({ name: 'Login' });
    }
  } else if (to.meta.requiresAdmin && !store.state.user || to.meta.requiresAdmin && !store.state.user.admin) {
    next({ name: 'Login' });
  } else {
    next();
  }
})

export default router;