import Vue from "vue";
import VueToastify from "vue-toastify";
import moment from "vue-moment";
import App from "./App.vue";
import "./assets/styles/index.css";
import http from "./services/http";
import Multiselect from 'vue-multiselect';
import Pagination from "vue-pagination-2";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSocketIO from "vue-socket.io";
import VueTailwind from "vue-tailwind";
import VModal from 'vue-js-modal';
import VueApexCharts from 'vue-apexcharts';
import vSelect from 'vue-select'
import VTooltip from 'v-tooltip'
import 'vue-select/dist/vue-select.css';
import { TDropdown, TDialog } from "vue-tailwind/dist/components";

const settings = {
  "t-dialog": {
    component: TDialog,
    props: {
      fixedClasses: {
        overlay:
          "overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed",
        wrapper: "relative mx-auto",
        modal: "overflow-visible relative ",
        close: "flex items-center justify-center",
        dialog: "overflow-visible relative",
      },
      classes: {
        close:
          "bg-gray-100 text-gray-600 rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out hover:bg-gray-200 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50",
        closeIcon: "fill-current h-4 w-4",

        overlay: "z-40 bg-black bg-opacity-50",
        wrapper: "z-50 max-w-lg px-3 py-12",
        dialog: "bg-white shadow rounded text-left",

        body: "p-3 space-y-3",
        buttons: "p-3 flex space-x-4 justify-center bg-gray-100 rounded-b",

        iconWrapper:
          "bg-gray-100 flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto",
        icon: "w-6 h-6 text-gray-500",
        content: "w-full flex justify-center flex-col",

        titleWrapper: "",
        title: "text-lg font-semibold text-center",

        textWrapper: "text-left w-full",
        text: "",

        cancelButton:
          "block px-4 py-2 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-100 focus:border-gray-100 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs",
        okButton:
          "block px-4 py-2 text-white transition duration-100 ease-in-out bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600 focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs",

        inputWrapper: "mt-3 flex items-center space-x-3",

        input:
          "block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full",
        select:
          "block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full",

        radioWrapper: "flex items-center space-x-2",
        radio:
          "text-green-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
        radioText: "",

        checkboxWrapper: "flex items-center space-x-2",
        checkbox:
          "text-green-500 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-green-500 focus:ring-2 focus:ring-green-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
        checkboxText: "",

        errorMessage: "text-red-500 block text-sm",

        busyWrapper:
          "absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full",
        busyIcon: "animate-spin h-6 w-6 fill-current text-gray-500",

        overlayEnterClass: "opacity-0",
        overlayEnterActiveClass: "transition ease-out duration-100",
        overlayEnterToClass: "opacity-100",
        overlayLeaveClass: "opacity-100",
        overlayLeaveActiveClass: "transition ease-in duration-75",
        overlayLeaveToClass: "opacity-0",

        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: "",
      },
    },
  },
  "t-dropdown": {
    component: TDropdown,
    props: {
      fixedClasses: {
        button:
          "flex text-sm font-medium items-center text-white block px-4 py-2 transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-green-1000 focus:ring-2 focus:ring-green-900 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
        wrapper: "inline-flex flex-col",
        dropdownWrapper: "relative z-10",
        dropdown: "origin-top-left absolute left-0 w-56 rounded shadow mt-1",
        enterClass: "opacity-0 scale-95",
        enterActiveClass: "transition transform ease-out duration-100",
        enterToClass: "opacity-100 scale-100",
        leaveClass: "opacity-100 scale-100",
        leaveActiveClass: "transition transform ease-in duration-75",
        leaveToClass: "opacity-0 scale-95",
      },
      classes: {
        button: "bg-green-1000 hover:bg-secondary",
        dropdown: "bg-white",
      },
    },
  },
};


Vue.use(VueTailwind, settings);
Vue.use(VTooltip, {
  defaultHtml: false,
});
Vue.use(VueToastify);
Vue.component('apexchart', VueApexCharts);
Vue.component('multiselect', Multiselect);
Vue.component('v-select', vSelect)
Vue.component("pagination", Pagination);
Vue.use(VueLoading);
Vue.use(VModal, { componentName: 'modal' });
Vue.use(moment);
Vue.config.productionTip = false;
Vue.prototype.$http = http;

import router from "./router";
import store from "./store";
import i18n from "./i18n";
import './registerServiceWorker'

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: process.env.VUE_APP_API_SOCKET,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
    options: { path: "/socket.io/" }, //Optional options
  })
);

Vue.mixin({
  data() {
    return {
      api: window.location.hostname === 'uno.trestto.com.br' ? 'https://uno.trestto.com.br/api' : process.env.VUE_APP_API,
    };
  },
  methods: {
    formatPrice(value) {
      if (!value) return '0,00';
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    },
    uppercaseWords(str) {
      return str.toLowerCase().replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
    },
    invertColor: function (hex, bw) {
      if (hex) {
        if (hex.indexOf('#') === 0) {
          hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
          hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
          throw new Error('Invalid HEX color.');
        }
        var r = parseInt(hex.slice(0, 2), 16),
          g = parseInt(hex.slice(2, 4), 16),
          b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
          // http://stackoverflow.com/a/3943023/112731
          return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
      } else {
        return "#fff";
      }
    }
  },
  beforeMount() {
    this.api = window.location.hostname === 'uno.trestto.com.br' ? 'https://uno.trestto.com.br/api' : process.env.VUE_APP_API;
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
