<template>
  <nav :style="{ 'background-color': $store.state.colorPrincipal }">
    <div class="mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between md:h-16">
        <div class="absolute top-0 left-0 flex items-center sm:hidden">
          <button
            @click="open = !open"
            type="button"
            class="inline-flex items-center justify-center py-2 px-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <svg :class="{ block: !open, hidden: open }" v-show="!open" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>

            <svg :class="{ block: open, hidden: !open }" v-show="open" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center sm:items-stretch justify-start">
          <div class="flex-shrink-0 items-center hidden sm:block">
            <span>
              <img v-if="$store.state.logo && $store.state.logo.filename" class="h-11" :src="`${api}/upload?mimetype=image/jpeg&filename=${$store.state.logo.filename}&folder=arquivosWhats`" />
              <img v-else-if="$store.state.user.lzv2.cliente_novavida" class="h-11" src="@/assets/logo-nova.png" />
              <h1 v-else class="text-white text-sm font-bold mt-2">BestMessage V2</h1>
            </span>
          </div>
          <div class="mt-10 sm:mt-0 sm:ml-2">
            <div class="md:flex space-x-1 2xl:space-x-2" :class="open ? 'block' : 'hidden'">
              <li class="list-none" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_chats') !== -1">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/home"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Home
                </router-link>
              </li>
              
              <li class="list-none" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('campanhas') !== -1">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/campanhas"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Campanhas
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('validacao') !== -1">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/temzap"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Validação
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('chats') !== -1">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/chats"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Conversas
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.operador && $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('chats') !== -1">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/avaliacoes"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Avaliações
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.admin || $store.state.user.visualizarChat">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/operadores"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Operadores
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.admin && $store.state.user.lzv2 && $store.state.user.lzv2.centrocusto">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/centros"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Centros de custo
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') !== -1">
                <!--  -->
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/numeros"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Números
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') !== -1">
                <!--  -->
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/templates"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Templates
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') !== -1">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/chatbot"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  ChatBot
                </router-link>
              </li>

              <li class="list-none" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('configuracao') !== -1">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/config"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Configuração
                </router-link>
              </li>

              <li v-if="$store.state.user.lzv2.cliente_novavida" class="list-none">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/tag"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Tabulação
                </router-link>
              </li>

              <li v-if="$store.state.user.lzv2.cliente_novavida" class="list-none">
                <router-link
                  active-class="underline"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  @click.native="
                    open = false;
                    dropRelatorios = false;
                  "
                  to="/numerosproibido"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Números proibidos
                </router-link>
              </li>

              <li
                v-if="
                  ($store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_validacao') !== -1) ||
                  ($store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_campanha') !== -1)
                "
                class="list-none relative z-50"
                @mouseover="dropRelatorios = true"
              >
                <button
                  type="button"
                  :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                  v-if="
                    ($store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_validacao') !== -1) ||
                    ($store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_campanha') !== -1)
                  "
                  @click="dropRelatorios = true"
                  class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                >
                  Relatórios
                </button>
                <div
                  class="bg-secondary rounded-md absolute -bottom-30 mt-1 w-60 left-0 px-2 py-2"
                  :style="{ 'background-color': $store.state.colorPrincipal }"
                  @mouseover="dropRelatorios = true"
                  @mouseleave="dropRelatorios = false"
                  v-show="dropRelatorios"
                >
                  <router-link
                    v-if="
                      ($store.state.user && $store.state.user.admin && $store.state.user._id.toString() === '626a87fec9cf2abcd1265acf') ||
                      ($store.state.user && $store.state.user.admin && $store.state.user._id.toString() === '63fe25095bd52da93a558322')
                    "
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-envio-clientes"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Envios clientes
                  </router-link>
                  <router-link
                    v-if="
                      ($store.state.user.admin && $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('campanhas') !== -1) ||
                      ($store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('rastrear') !== -1)
                    "
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/rastrear"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Rastrear envio
                  </router-link>

                  <router-link
                    v-if="$store.state.user.admin && $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('respostas') !== -1"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/respostas"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Respostas
                  </router-link>

                  <router-link
                    v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_resumido') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-resumo"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório resumo envios
                  </router-link>

                  <router-link
                    v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_validacao') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-temzap"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório validação
                  </router-link>

                  <router-link
                    v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_campanha') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-envio"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório envio
                  </router-link>

                  <router-link
                    v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_campanha') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-analitico"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório analítico
                  </router-link>

                  <router-link
                    v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_chats') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-chats"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório conversas
                  </router-link>

                  <router-link
                    v-if="$store.state.user.admin && $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_chats') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-chats-finalizados"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório chats finalizados
                  </router-link>

                  <router-link
                    v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_chats') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-diario"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório diário
                  </router-link>

                  <router-link
                    v-if="$store.state.user.admin && $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_chats') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/relatorio-sessao"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório sessão
                  </router-link>

                  <router-link
                    v-if="$store.state.user.admin && $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_chats') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/auditoria"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Relatório auditoria
                  </router-link>

                  <router-link
                    v-if="$store.state.user.admin && $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('relatorio_chats') !== -1"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/faturamento"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Faturamento
                  </router-link>

                  <router-link
                    v-if="$store.state.user.root"
                    @click.native="
                      open = false;
                      dropRelatorios = false;
                    "
                    to="/relatorios/auditoria"
                    :style="{ color: invertColor($store.state.colorPrincipal, true) }"
                    class="block text-white hover:underline hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
                  >
                    Auditoria
                  </router-link>
                </div>
              </li>
            </div>
          </div>
        </div>

        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <span
            v-if="$store.state.saldoPrePago.ativo"
            class="hidden sm:block text-white hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium"
            :style="{ color: invertColor($store.state.colorPrincipal, true) }"
          >
            Saldo: {{ $store.state.saldoPrePago.credito }}
          </span>
          <router-link to="/user" :style="{ color: invertColor($store.state.colorPrincipal, true) }" class="hidden sm:block text-white hover:text-white px-2 py-2 my-1 md:my-0 rounded-md text-sm font-medium">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline mr-1">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <b>{{ $store.state.user.nome }}</b>
          </router-link>
          <a v-if="!open" @click="logout" :style="{ color: invertColor($store.state.colorPrincipal, true) }" class="text-white px-2 py-2 rounded-md text-sm font-medium" href="javascript:void(0)">
            {{ $t("txt-signout") }}
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      open: false,
      dropRelatorios: false,
      relatorioOpen: false,
      langs: ["ptBr", "en"]
    };
  },
  methods: {
    async logout() {
      if (this.$store.state.user.operador) {
        await this.$http.post("/v1/operador/sessao/logout", { id: this.$store.state.user._id });

        if (this.$store.state.user.lzv2.moverConversasOperadorLogout) {
          this.$confirm({
            title: "Mover suas conversas",
            message: "Mover suas conversa para demais operadores do seu centro de custo ao fazer logout ?",
            button: { no: "Não", yes: "Sim" },
            callback: async (confirm) => {
              await this.$http.put(`/v1/operador`, {
                _id: this.$store.state.user._id,
                onlinelzv2: false,
                horaonline: new Date(),
                moverConversas: confirm
              });

              this.$store.commit("logout");
              this.$router.push({ path: "/login" }).catch(() => {});
            }
          });
          return;
        }

        await this.$http.put(`/v1/operador`, {
          _id: this.$store.state.user._id,
          onlinelzv2: false,
          horaonline: new Date()
        });
      }
      this.$store.commit("logout");
      this.$router.push({ path: "/login" }).catch(() => {});
    }
  }
};
</script>
